const host = process.env.VUE_APP_GSAPI;
const host_ccapi = process.env.VUE_APP_CCAPI;
const key = process.env.VUE_APP_GSAPI_KEY;
import api from '@/api/api';

export default class Application {

    async updateApplicationToExported(guid) {
        const resource = `/vs/application/generate_policy`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers:{
                'Content-Type': 'application/json'
            }
        };

        const params = {
            guid: guid
        };

        let response = await api.httpPost(API_URL, config, params);
        const resp = response.data.response.data
        const status = response.status
        return { resp, status };
	}

    async getClientAndPolicy(guid) {
		const resource = '/vs/application/get_client_and_policy';
        const API_URL = `${host}${resource}`;

        const config = {
            headers:{
                'Content-Type': 'application/json'
            }
        };

        const params = {
            guid: guid
        };

        const response = await api.httpGet(API_URL, config, params);
        const resp = response.data.response
        const status = response.status
        return { resp, status };
	}
}