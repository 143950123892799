const host = process.env.VUE_APP_GSAPI;
const host_ccapi = process.env.VUE_APP_CCAPI;
const key = process.env.VUE_APP_GSAPI_KEY;
import api from '@/api/api';
import { getToken as getTokenFromStorage } from '@/helpers/tokenauth.js'; 

export default class Payment {
    async getPolicyPaymentData() {
		
        const resource = '/general-seguros/get_policy_payment_data';
        const API_URL = `${host_ccapi}${resource}`;

        const config = {
            headers:{
                'Content-Type': 'application/json',
            }
        };

        const params = {
            insurance: "general de seguros",
            product_name: "Vida simple"
        };

        const response = await api.httpGet(API_URL, config, params);
        const resp = response.data.response.data
        const status = response.status
        return { resp, status };
	}

    async getPolicyPaymentUrl(client_gs_number,policy_number,branch_number,agent_number,policy_branch,guid,petition_from) {
       
        const resource = '/general-seguros/get_policy_payment_url';
        const API_URL = `${host_ccapi}${resource}`;

        const config = {
            headers:{
                'Content-Type': 'application/json',
            }
        };

        const params = {
            client_gs_number,
            policy_number,
            branch_number,
            agent_number,
            policy_branch,
            petition_from,
            guid
        };

        const response = await api.httpGet(API_URL, config, params);
        const resp = response.data.response
		const status = response.status
        return { resp, status };
	}

}