<template>
<div class="" style="background: #fff">
    <Loading :isLoading="isLoading" />
    <Menu :list="menuList" />
    <div class="my-32">
        <div class="flex justify-center pb-5 w-full" style="height: 350px;">
            <div id="image-cheked">
                <div class="content-white" ></div>
                <div class="icon-cheked">&#10003;</div>
            </div>
        </div>
        <div class="flex justify-center pb-5 w-full">
            <h1 class="text-4xl mb-5 ">
                Compra realizada exitosamente.
            </h1>
        </div>
        <!-- <div v-if="showLinkPayment" class="flex justify-center pb-5 w-full">
            <h3 class="text-2xl">
                Desea realizar el pago de su primer recibo, favor de dar <button class="text-blue-700 hover:text-blue-900 hover:underline" v-on:click="getPolicyPaymentUrl()">clic aqui.</button>
            </h3>
        </div> -->
    </div>
    <Footer />
</div>
</template>

<script>
import('@/assets/css/image-checked.css');

import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import {
    ref,
    onBeforeMount
} from 'vue';

import menuOptions from "@/helpers/menu.js";
import { useRouter } from 'vue-router';

import Quote from "@/classes/Quote";
import Application from '@/classes/Application';
import Payment from '@/classes/Payment';

export default {
    name: "SuccessfulSigning",
    setup() {

        const router = useRouter();

        const applicationClass = ref(null);
        const paymentClass = ref(null);
        const quoteClass = ref(null);

        const showLinkPayment = ref(false);
        const menuList = ref([]);
        const guid = ref("");
        const clientgsNumber = ref("")
        const policyNumber = ref("")
        const branchNumber = ref("")
        const agentNumber = ref("")
        const policyBranch = ref("")

        const isLoading = ref(false);

        onBeforeMount(async () => {
            guid.value = router.currentRoute.value.params.guid.toString();
            quoteClass.value = new Quote();
            applicationClass.value = new Application();
            paymentClass.value = new Payment();

            await updateApplicationToExported();
            await getMenuOptionsList();
        });
        
        const getMenuOptionsList = async () => {
            await menuOptions.getMenuOptions().then(resp => {
                menuList.value = resp.data.FOBEN_SOCAP;
            }).catch(err => {
            });
        };

        async function updateApplicationToExported(){
            
            const resp = applicationClass.value.updateApplicationToExported(guid.value)
            isLoading.value = true;
            resp.then((info) => {
                // console.log(info)
                const { status } = info

                if(status == 200){
                    isLoading.value = false;
                }else{
                    isLoading.value = false;
                }

            }).catch((ex)=>{
                isLoading.value = false;
                Swal.fire({
                    icon:'warning',
                    title:"Cuidado!",
                    html:ex.response.data.response.message
                })
            }).finally(()=>{
                getClientAndPolicy();
                getPolicyPaymentData();
                showLinkPayment.value=true;
            });

        }
        
        const getTokenStorage = (name) => {
            return sessionStorage.getItem(name);
        }

        const createStorage = (name, value) => {
            sessionStorage.setItem(name, JSON.stringify(value));
        }

        async function getClientAndPolicy(){

            const resp = applicationClass.value.getClientAndPolicy(guid.value)
            await resp.then((info) => {

                const { resp,status } = info

                if(status == 200){
                    clientgsNumber.value = resp.number_client_contracting_party;
                    policyNumber.value = resp.policy_number;
                }
            }).catch((ex)=>{
                Swal.fire({
                    icon:'warning',
                    title:"Cuidado!",
                    html:ex.message
                })
            });
        }

        async function getPolicyPaymentData(){

            const resp = paymentClass.value.getPolicyPaymentData()
            resp.then((info) => {

                const { resp,status } = info

                if(status == 200){
                    branchNumber.value = resp.branch_number
                    agentNumber.value = resp.agent_number
                    policyBranch.value = resp.policy_branch
                }
            }).catch((ex)=>{
                Swal.fire({
                    icon:'warning',
                    title:"Cuidado!",
                    html:ex.message
                })
            });
        }

        async function getPolicyPaymentUrl(){
            const resp = paymentClass.value.getPolicyPaymentUrl(
                clientgsNumber.value,
                policyNumber.value,
                branchNumber.value,
                agentNumber.value,
                policyBranch.value,
                guid.value,
                "pagina web"
            )
            resp.then((info) => {

                const { resp,status } = info
                if(status == 200){
                    window.open(resp.url, "_blank");
                }
            }).catch((ex)=>{
                Swal.fire({
                    icon:'warning',
                    title:"Cuidado!",
                    html:ex.message
                })
            });
        }
        
        return {
            menuList,
            showLinkPayment,
            isLoading,
            getPolicyPaymentUrl
        }
    },
    components: {
        Menu,
        Footer,
        Loading
    },
};
</script>
